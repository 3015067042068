h1,
h2 {
  color: #1f1f1f;

  a {
    color: #fff;
  }
}

h1 {
  font-size: 3em;
  font-weight: 600;
}

h2 {
  font-size: 2.2em;
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
}

h3 {
  font-size: 1.5em;
  font-weight: 600;
}

h6 {
  font-weight: 500;
}

a.nav-link {
  color: #fff;
  position: relative;

  &:hover {
    color: #fff;

    &::before {
      width: 100%;
    }
  }

  &:focus {
    color: #fff;
  }
}

.w-lg-fit-content {
  @media (min-width: 991px) {
    width: fit-content !important;
  }
}

.gender-select-link {
  background: $sidebar-color;
  display: inline-block;
  max-width: 250px;
  width: 50%;
  min-width: 150px;
  margin: 0 15px;
  padding: 10px;
  border-radius: 30px;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  transition: all 0.5s ease;

  &:hover {
    box-shadow: none;
    color: #fff;
  }
}

.navbar .dropdown .dropdown-menu {
  border-radius: 10px;
  background-color: #c0f1ea;

  button {
    color: $green;
  }
}

@media all and (min-width: 992px) {
  .navbar .dropdown .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    margin-top: 0;
  }

  .navbar .dropdown .dropdown-menu button {
    padding: 5px 10px;
  }
  .navbar .dropdown .dropdown-menu button:hover {
    color: #fff;
    background-color: $green;
  }

  .navbar .dropdown-menu.fade-down {
    top: 80%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
  }

  .navbar .dropdown-menu.fade-up {
    top: 180%;
  }

  .navbar .dropdown:hover .dropdown-menu {
    transition: 0.3s;
    opacity: 1;
    visibility: visible;
    top: 100%;
    transform: rotateX(0deg);
  }
}

// custom dropdown
.custom-dropdown {
  position: relative;
}

.custom-dropdown .dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
}

.custom-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 10%;
  list-style: none;
  border-radius: 10px;
  background-color: #c0f1ea;
  padding: 0;
  margin: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.custom-dropdown .dropdown-toggle::after {
  display: none;
}

.custom-dropdown .dropdown-menu li {
  padding: 5px 10px;
}

.custom-dropdown .dropdown-menu li:hover,
.custom-dropdown .dropdown-menu li a:hover {
  background-color: #176c5a !important;
  color: #fff;
}
.custom-dropdown .dropdown-menu li:hover a {
  color: #fff;
}

.custom-dropdown .dropdown-menu li a {
  color: #333;
  text-decoration: none;
  color: #176c5a;
  padding: 0;
  display: block;
  font-size: 0.9rem !important;
}

.custom-dropdown.open .dropdown-menu {
  display: block;
}

.top-nav-link {
  position: relative;

  &:hover {
    &::before {
      width: 100%;
    }
  }

  &::before {
    content: '';
    width: 0;
    height: 2px;
    position: absolute;
    bottom: -7px;
    left: 0;
    background: #fff;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
}

.mainLogo {
  max-height: 48px;
}

.bg-white {
  background-color: #fff;
}

.signup-btn {
  width: 174px;
  height: 45px;
  color: #fff;
  border: 1px solid #ffffff;
  border-radius: 30px !important;
  background: transparent;

  font-weight: 500;
  font-size: 1em;
  line-height: 26px;

  &:hover {
    background-color: $navbar-color;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
    border-color: #fff;
  }
}

input[type='date'] {
  position: relative;
  min-height: 45px;
}

// input[type='date']:after {
//   content: url(/assets/images/icons/calendar-w.svg);
//   padding: 0 5px;
//   position: absolute;
//   right: 5px;
//   top: 50%;
//   transform: translateY(-40%);
// }
// input.text-dark[type='date']:after {
//   content: url(/assets/images/icons/calendar.svg);
//   padding: 0 5px;
//   position: absolute;
//   right: 10px;
//   top: 50%;
//   transform: translateY(-40%);
// }

input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

/* adjust increase/decrease button */
input[type='date']::-webkit-inner-spin-button {
  z-index: 1;
}

/* adjust clear button */
input[type='date']::-webkit-clear-button {
  z-index: 1;
}

.form-check-input:checked {
  background-color: $navbar-color;
  border-color: $sidebar-color;
}

.border-green {
  border: 1.2px solid #abe6da !important;
}

.form-check-input {
  width: 1.2rem;
  height: 1.2rem;
  background-color: transparent;
}

.main-checkbox {
  input[type='radio'] {
    display: none;
  }

  &:checked {
    + .box {
      .circle-bg {
        background-color: $green;
      }

      p {
        color: $green;
      }

      .bottom-line,
      .bottom-line-green {
        background-color: $green;
      }
    }
  }
}

.checked {
  .box {
    .circle-bg {
      background-color: $green;
    }

    p {
      color: $green;
    }

    .bottom-line,
    .bottom-line-green {
      background-color: $green;
    }
  }
}

.dob {
  width: 100%;
  max-width: 600px;
  background-color: transparent;
  border: 1.2px solid;
  color: #fff;
  border-color: #abe6da !important;
}

.dob:focus {
  color: #1e2a35;
  background-color: transparent;
  border-color: $sidebar-color;
  outline: 0;
}

.error-border {
  border-color: red !important;
}

.error-text {
  color: red !important;
  font-size: 0.9rem;
  display: block;
  text-align: left;
  background: #ffcccc;
  margin-top: 4px;
  position: relative;
  width: fit-content;
  border-radius: 3px;
  padding: 0 10px;
  line-height: 24px !important;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  max-width: 90%;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 25px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 10px solid #ffcccc;
    z-index: 2;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  }
}
.uom-slider-wrapper {
  flex-direction: column;
  gap: 30px;
}

.uom-units-wrapper {
  flex-direction: column;
  gap: 30px;
  padding-bottom: 8px;
}
.uom-units-stacked-wrapper {
  .sub-title-chart {
    text-align: center;
  }

  div.radio {
    justify-content: center !important;
    gap: 60px;
  }
}

.hero {
  vertical-align: middle;
  min-height: 400px;
  height: 100%;

  .content-container {
    width: 70%;
    text-align: center;
    padding-top: 1.5rem;
    // padding-bottom: 2rem;
    padding-bottom: 12rem;

    span {
      font-weight: 500;
      color: #ccfff5;
      line-height: 38px;
    }
  }
}

.banner-heading {
  color: #fff;
}

/* !Home Icons - - - - - */
.stop-top {
  top: 0 !important;
}

svg.full,
img.full {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.home-start-icon,
.green-home-start-icon {
  width: 50px;
  margin: 0 auto;
}

.home-icons {
  height: 300px;
  position: absolute;
  bottom: -150px;
  left: 0;
  width: 100%;
  z-index: 2;
  text-align: center;
}

.home-icons:before,
.home-icons:after {
  content: '';
  width: 3px;
  height: 150px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  background: #fff;
  z-index: 1;
}

.home-icons:after {
  top: 150px;
  background: $navbar-color;
}

.home-icons-top {
  height: 150px;
  overflow: hidden;
  position: relative;
}

.home-icons-bottom {
  height: 150px;
  overflow: hidden;
  position: relative;
}

.home-start-icon,
.green-home-start-icon {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -25px;
  z-index: 100;
  cursor: pointer;
}

.start-today {
  cursor: pointer;
}

.green-home-start-icon {
  top: -150px;
}

.green-home-start-icon.stop {
  top: 100px !important;
}

.circle-bg {
  background: #fff;
  border-radius: 50%;
  display: flex;
  width: 75px;
  height: 75px;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
.gender-select-icon {
  width: 31px;
  height: 37px;
}

.circle-bg-green {
  background-color: #c0f1ea;
}

.circle-bg-active {
  background-color: #176c5a;
}

.bottom-line {
  max-width: 300px;
  width: 100%;
  // width: 300px;
  height: 5px;
  background-color: #fff;
  margin: 1rem 0;
}

.bottom-line-green {
  background-color: #c0f1ea;
}

.main {
  min-height: 400px;
  background-color: #25af9b;
  background-size: 80%;
  background-repeat: no-repeat;
  height: 100%;
  background-position: center center;
  border-bottom: 1px solid #e8d6c1;

  .cartoon-icon {
    background: url('/assets/images/logos/LM-sprite.png') -1270px -120px;
    height: 110px;
    width: 140px;
    background-size: 717px;
    position: relative;
    left: 80%;
    bottom: 50px;
  }

  .start-now {
    color: #fff;
    background-clip: padding-box;
    padding: 0.2em 1.5em;
    border: 4px solid;
    font-size: 1.2em;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 20px;
    text-transform: none;
    background: linear-gradient(180deg, #ffab00 0%, #ffc040 100%);
    border: 1px solid #ffac02;
    border-radius: 60px;
    height: 60px;
    max-width: 255px;
  }

  .lines {
    max-width: 313px;
    margin: 50px auto;
  }

  .start-now:hover {
    opacity: 0.9;
  }

  .cartoon-information {
    position: relative;
    top: 1em;
    z-index: 0;
    padding: 0 1.2rem;
    margin-top: 100px;

    md-button {
      display: inline-block;
    }

    p {
      display: inline-block;
      width: 66px;
      margin-right: -66px;
      padding-top: 20px;
      vertical-align: bottom;
    }
  }

  .getting-started {
    max-width: 600px;
    margin: 0 auto;

    .radio,
    .checkbox {
      padding: 0 15px;
    }

    .md-padding {
      padding: 20px 0;
    }

    md-radio-button.md-checked .md-off {
      border-color: #31514a;
    }

    md-radio-button.md-checked .md-on {
      background-color: #31514a;
    }

    .sub-title-chart {
      color: #e9fffa;
      font-size: 1em;
      font-weight: 400;
      padding-top: 4px;
    }
  }
}

.getting-started-section {
  min-height: 400px;
  padding-top: 12rem;

  h2 {
    color: #1f1f1f !important;
    text-align: center;
    font-weight: 600;
  }

  .middle-row {
    text-align: center;
    align-items: center;
    text-align: center;

    h3 {
      margin-top: 1rem;
      color: #1f1f1f;
      font-size: 1.13rem;
      font-weight: 500;
    }

    img {
      max-width: 100%;
      padding: 2.8em 2.5em;
    }

    p {
      color: $text-dark !important;
      font-size: 1.2em;
      padding: 1em;
    }

    .icon-home {
      border-radius: 100%;
      height: 130px;
      width: 130px;
      display: inline-block;
      position: relative;

      &::after {
        content: '';
        content: '';
        width: 17px;
        height: 2px;
        background-color: #1b9078;
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .icon-home:hover {
      background: rgba(214, 234, 231, 100);
      transition: all 0.2s ease-in-out;
      height: 130px;
      width: 132px;
    }
  }
}

@media (max-width: 600px) {
  .hero .content-container {
    padding: 2rem 15px 8rem 15px;
    min-height: 430px;
    width: 100%;
  }

  .hero .content-container h1 {
    font-size: 1.5em;
    line-height: 38px;
    font-weight: 400;
  }

  .breadcrumb {
    padding: 15px 2px 0;
    margin-bottom: 0;
    font-size: smaller;
  }

  .cartoon-icon {
    display: none;
  }
}

.radio-toolbar {
  // width: 100%;
  justify-content: center;

  input[type='radio'] {
    display: none;
  }

  .cardButton-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .circle {
    background-color: #fff;
    border-radius: 50%;
    height: 75px;
    width: 75px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #1b9078;

    img {
      height: 40px;
    }
  }

  .cardButton {
    color: #363636;
    background: #ccfff5;
    border: none;
    font-size: 1em;
    font-weight: 500;
    padding: 30px 10px;
    // width: 22%;
    max-width: 190px;
    margin: 2px;
    border-radius: 2px !important;
  }

  @media screen and (max-device-width: 640px) {
    label {
      height: 75px;
    }

    .circle {
      width: 54px;
      height: 54px;

      img {
        width: 25px;
        height: 28px;
      }
    }

    .cardButton {
      padding: 10px;
    }
  }

  .cardButton:hover {
    box-shadow: 0px 2px 4px #8f9290;
    cursor: pointer;
    background: #c0f1ea;
  }

  .cardButton.active {
    background: #176c5a;
    color: #fff;
  }

  .weight,
  .height,
  .head,
  .bmi {
    margin: 0 auto;
  }

  .weight {
    background: url('/assets/images/logos/LM-sprite.png') no-repeat -349px -532px;
    width: 35px;
    height: 44px;
  }

  .height {
    background: url('/assets/images/logos/LM-sprite.png') no-repeat -397px -529px;
    width: 19px;
    height: 44px;
  }

  .head {
    background: url('/assets/images/logos/LM-sprite.png') no-repeat -429px -533px;
    width: 36px;
    height: 44px;
  }

  .bmi {
    background: url('/assets/images/logos/LM-sprite.png') no-repeat -470px -534px;
    width: 40px;
    height: 44px;
  }
}

/*Charts on home page*/
.chart-section {
  min-height: 600px;
  width: 100%;
  background-position: center center;
  background-size: cover;

  a {
    text-decoration: none;
  }

  h2 {
    color: #1f1f1f !important;
    margin-bottom: 60px;
    text-align: center;
    // text-shadow: 0px 0px 2px #333;
    font-weight: 600;
  }

  .boys {
    background-color: $navbar-color;
  }

  .girls {
    background-color: #f3ba46;
  }

  .card {
    margin-top: 10rem;
    margin-bottom: 5rem;

    .card-img {
      // height: 369px;
      margin-top: -10rem;
      object-fit: contain;
    }

    .card-body {
      h3 {
        color: #ffffff !important;
        font-weight: 600;
        text-align: center;
        margin: 20px auto;
      }

      .card-links-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;

        @media (max-width: 500px) {
          grid-template-columns: 1fr 1fr;
        }
      }

      .card-links {
        min-width: 90px;
        max-width: 150px;
        color: #1f1f1f;
        background-color: #ffffff;
        padding: 15px 20px;
        text-align: center;
        font-weight: 500;
        position: relative;
        z-index: 1;

        svg {
          height: 20px;
          width: 20px;
          margin-right: 5px;
        }

        &:hover {
          stroke: #fff;

          path {
            fill: #fff;
          }

          &::before {
            width: 100%;
          }

          color: #fff;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 0px;
          background-color: $dark-green;
          height: 100%;
          z-index: -1;
          transition: 0.3s ease;
        }
      }
    }

    @media (max-width: 600px) {
      .card-img {
        // height: 200px;
        margin-top: -7rem;
        height: auto;
      }

      .card-body {
        h3 {
          margin-top: 0;
        }

        .card-links-wrapper {
          margin-bottom: 20px;
        }
      }
    }
  }

  .graphs-home {
    color: #fff;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 100%;
    min-width: 290px;
    height: 100%;
    min-height: 100px;
    overflow: hidden;
    font-size: 25px;
    margin: 10px;
    padding: 15px;
    position: relative;
    text-shadow: 0 0 6px #333;
  }

  .graphs-home.boys {
    background: rgba(178, 190, 253, 0.2);
    border: 1px solid rgba(178, 190, 253, 0.6);
  }

  .graphs-home.girls {
    background: rgba(253, 178, 178, 0.2);
    border: 1px solid rgba(253, 178, 178, 0.6);
  }

  .graphs-home.boys:hover {
    background: rgba(178, 190, 253, 0.3);
  }

  .graphs-home.girls:hover {
    background: rgba(253, 178, 178, 0.3);
  }

  .graph-icon {
    background: url('/assets/images/logos/LM-sprite.png') -710px -100px no-repeat;
    height: 141px;
    width: 163px;
    position: absolute;
    left: 350px;
    bottom: 30px;
    zoom: 0.5;
    filter: drop-shadow(0px 0px 2px #333);
  }

  .orange-dot {
    background: url('/assets/images/logos/LM-sprite.png') -678px -182px no-repeat;
    height: 19px;
    width: 18px;
    position: absolute;
    left: 290px;
    top: 140px;
    zoom: 0.6;
  }

  .orange-dot.weight-girls {
    left: 316px;
    top: 80px;
  }

  .orange-dot.height-girls {
    left: 338px;
    top: 50px;
  }

  .orange-dot.bmi-girls {
    left: 316px;
    top: 50px;
  }

  .orange-dot.weight-boys {
    left: 396px;
    top: 29px;
  }

  .orange-dot.height-boys {
    left: 382px;
    top: 50px;
  }

  .orange-dot.bmi-boys {
    left: 350px;
    top: 60px;
  }
}

.max-width {
  max-width: 1000px;
  margin: 0 auto;
}

.layout-align-center,
.layout-align-center-start,
.layout-align-center-center,
.layout-align-center-end,
.layout-align-center-stretch {
  justify-content: center;
}

@media (max-width: 585px) {
  .top-section .cartoon-information p {
    display: block;
    margin: 0;
    padding: 0 0 20px;
    width: 100%;
  }

  .cartoon-icon {
    display: none;
  }

  .chart-section {
    h2 {
      font-size: 1.5em;
      margin-bottom: 20px;
    }
  }
}

.notFound {
  background-color: #3cb499 !important;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

/*
  Sign in/up pages
*/
.signin-conatiner {
  // span,
  // p {
  //   font-family: 'Poppins';
  // }
  .left-panel {
    display: flex;
    align-items: center;
    height: 100vh;
    /* position: fixed; */
    flex-direction: column;
    position: fixed;
    justify-content: center;
    width: calc(100% - 60%);

    h4 {
      color: #fff;
      font-weight: 600;
      font-size: 2rem;
      margin-bottom: 2rem;
    }

    img {
      max-width: 350px;
      width: 100%;
    }

    @media (max-width: 778px) {
      position: unset;
      width: 100%;
      height: unset;
      flex-direction: column-reverse;
      padding-top: 20px;
      padding-bottom: 15px;

      h4 {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1.5rem;
      }
    }

    @media (max-width: 600px) {
      img {
        width: 125px;
        height: 125px;
      }
    }
  }

  .signin-btn {
    background-color: #fff;
    width: 100%;
    height: 40px;
    border: none;
    padding: 0 8px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #363636;
    cursor: pointer;
    max-width: 266px;
    position: relative;
    z-index: 1;

    svg {
      margin-right: 24px;
    }

    &:hover {
      &::before {
        width: 100%;
      }

      color: #fff;

      svg {
        fill: #fff;

        path {
          fill: #fff;
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0px;
      background-color: $dark-green;
      height: 100%;
      z-index: -1;
      transition: 0.3s ease;
    }
  }

  .social-login-btn {
    transition: background-color 0.3s, box-shadow 0.3s;
    position: relative;
    z-index: 1;
    padding: 12px 10px 12px 10px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;

    // background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    // background-repeat: no-repeat;
    // background-position: 12px 11px;

    svg {
      margin-right: 22px;
    }

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
    }

    &:active {
      background-color: #eeeeee;
    }

    &:focus {
      outline: none;
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc;
    }

    &:disabled {
      filter: grayscale(100%);
      background-color: #ebebeb;
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
      cursor: not-allowed;
    }
  }

  .span-forever {
    color: #1b9078;
    font-weight: 400;
    font-size: 16px;
  }

  .or-div {
    position: relative;

    &::after {
      content: '';
      max-width: 130px;
      width: 32%;
      height: 0px;
      border: 1px solid #cfcece;
      position: absolute;
      left: 12%;
      top: 12px;
    }

    &::before {
      content: '';
      max-width: 130px;
      width: 32%;
      height: 0px;
      border: 1px solid #cfcece;
      position: absolute;
      right: 12%;
      top: 12px;
    }
  }

  p.guide-text {
    font-size: 1rem;
  }

  button.btn-lg {
    max-width: 255px;
    width: 100%;
  }

  .home-link {
    text-align: center;
    text-decoration-line: underline;
    color: #25af9b;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}

.email-icon {
  position: absolute;
  right: 25px;
  top: 45px;
}

input.email {
  padding: 12px 15px;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
}

.benefits-section {
  justify-content: space-evenly;

  h6 {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #363636;
    line-height: 19px;
    // font-family: 'Poppins';
    margin: 10px 0;
  }
}

.auth-wrapper {
  .provider-wrapper {
    margin: 15px;
  }

  .list-group {
    margin-bottom: 10px;
  }
}

.md-whiteframe-5dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.add-family-member-link {
  border: none;
  background: $navbar-color;
  padding: 5px 8px;
  margin-left: -20px;
  height: 100%;

  svg {
    color: #fff;
  }

  img {
    min-width: 20px;
  }
}

.add-family-member-btn {
  width: 107px;
  position: absolute;
  bottom: 24px;
  right: 30px;
  min-width: unset !important;
  height: 36px;
}

/*
  Terms and conditions
*/

.terms {
  width: 100%;
  height: 125px;
  border: 1px solid #afd3cd;
  background: #fff;
  padding: 10px;
  overflow: auto;
  margin: 10px 0;
  text-align: justify;

  h3 {
    margin-top: 0px;
  }

  p {
    font-size: 0.8em;
  }
}

.save-modal {
  color: #4c4c4c;
  font-weight: 400;
  font-size: 1rem;

  .modal-header {
    justify-content: center;
    position: relative;

    h5 {
      font-size: 17px;
      font-weight: 600;
    }
  }

  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    background-color: transparent;

    &:hover {
      opacity: 0.8;
    }

    img {
      width: 15px;
    }
  }

  .modal-content {
    background-color: #f2f7f8;
    border-radius: 0;
  }

  ul {
    width: 80%;
    margin: 0 auto;

    li {
      list-style: none;
      margin: 10px 0;

      span {
        color: #4c4c4c;
        font-weight: 400;
        font-size: 1rem;
        line-height: 26px;
      }
    }
  }
}

.memberprofile {
  display: flex;
  align-items: center;
  justify-content: center;
  // font-family: Bitter;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  text-transform: capitalize;
  text-align: center;
  color: white;

  .icon {
    background: url('/assets/images/logos/LM-sprite.png') -463px -208px;
    background-repeat: no-repeat;
    background-size: 600px;
    display: block;
    height: 24px;
    width: 26px;
    margin: 0 auto;
  }

  span {
    color: #1f1f1f;
    font-weight: 500;
    font-size: 1.4em;
  }
}

.profile-btn {
  position: relative;

  &:hover {
    background-color: transparent;
    border-color: transparent;

    &::before {
      width: 100%;
    }
  }

  &::before {
    content: '';
    width: 0;
    height: 2px;
    position: absolute;
    bottom: -7px;
    left: 0;
    background: #fff;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
}

.chart-dropdown button {
  background: transparent;
  border: none;
  outline-color: transparent;
  width: 100%;

  &:hover,
  &:active,
  &:focus-visible {
    background: transparent;
  }
}

.privacy-link {
  color: $main-text-darker-color;
  text-decoration: underline;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.center-nav {
  // margin-left: $sidebarWidth;
  width: 100%;
  justify-content: center;
  background-color: $mainBody-color;
  position: relative;
}

.pos-corner-right {
  position: absolute !important;
  right: 0;

  .dropdown-menu {
    left: -75px;
  }
}

//mobile screen

@media (max-width: 600px) {
  h2 {
    font-size: 1.1em;
    line-height: 20px;
  }

  .main .cartoon-information {
    margin-top: 50px;
  }
  .circle-bg {
    width: 38px;
    height: 38px;
  }
  .gender-select-icon {
    width: 16px;
    height: 20px;
  }
  .bottom-line {
    margin: 8px 0;
  }
}

// arrow animation

.down-arrows {
  position: absolute;
  margin-top: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: rotate(0deg);
  cursor: pointer;
}

.down-arrows span {
  display: block;
  width: 20px;
  height: 20px;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.down-arrows span:nth-child(2) {
  animation-delay: -0.2s;
}

.down-arrows span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
.dropdown-toggle:after {
  content: unset !important;
}
.dark-green-color {
  color: $dark-green-color;
}
.green-color {
  color: $green-color;
}
