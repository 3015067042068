/*******************/
// Theme Fonts
/********************/
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

$font-family-sans-serif: "Poppins", sans-serif;
/*******************/
// Theme Colors
/********************/
$primary: #1e88e5;
$info: #26c6da;
$danger: #f62d51;
$success: #39c449;
$warning: #ffbc34;
$dark: #343a40;
$light: #eaf2fb;
$secondary: #35363b;
$transparent: transparent;
$green:#176C5A;
$dark-green:#15866E;
// $navbar-color: #31514a;
$navbar-color: #25AF9B;
$sidebar-color: #25AF9B;
$sidebar-secondary-color: #15866E;
$main-text-darker-color: #0F5C4D;
$sidebar-subtext: #fff;

$action-color: #FF8000;/* // orange call to action colour*/
$action-rollover: #FF4D00;/* // dark orange rollover*/
$text-dark: #46665F;/* // Helvetica Neue dark green text for card labels*/
$menu-bg-color: #528B7F; /*// medium green/grey bg for menu*/

$mainBody-color: rgb(242, 247, 248);
$calculator-green-color: #25AF9B;
$green-bg-color: #D9F9E6;
$dark-green-color: #176C5A;
$green-color: #1B9078;
$light-green: #D4FCF6;
$theme-colors: (
  "primary": $primary,
  "navbar-color": $navbar-color,
  "sidebar-color": $sidebar-color,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "light-primary": #d6e4f3,
  "light-success": #d5f3f2,
  "light-info": #d3edfa,
  "light-warning": #f8ecdc,
  "light-danger": #f8dddd,
  "transparent": $transparent,
  "green-bg-color": $green-bg-color,
  "dark-green-color": $dark-green-color,
  "green-color": $green-color,
  "light-green": $light-green,
);
// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #f2f7f8 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #757e85 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #1e2a35 !default;
$black: #000 !default;

/*******************/
// Theme Text Contrast
/********************/
$min-contrast-ratio: 2;
$font-weight-bold: 500 !default;
/*******************/
// Theme body bg & text color
/********************/
$body-bg: $gray-200 !default;
$body-color: $gray-900 !default;
$gradient: linear-gradient(-90deg, rgba($white, 0.5), rgba($white, 0)) !default;

/*******************/
// Theme common variable
/********************/
$border-radius: 0.3rem !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.05);
$headings-font-weight: 400 !default;

$card-border-width: 0 !default;
$card-box-shadow: $box-shadow !default;
$grid-gutter-width: 1.85rem !default;

$input-bg: $white !default;
/*******************/
// Theme btn
/********************/
$btn-focus-width: 0px;

/*******************/
// Theme sidebar width
/********************/
$sidebarWidth: 280px;
$sidebarColor: $sidebar-color;
$sidebarSecondaryColor: $sidebar-secondary-color;

$base: rgb(60,180,153);
$text: #555;
$invalid: #E91E63;
$width: 400px;
$cubic: cubic-bezier(.64,.09,.08,1);